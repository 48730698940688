import ApiBase from '@/api/base'

class ApiCustomPage {
	static getShortAll (self) {
		self.http('get', `${ApiBase.baseApiUrl()}/custom-pages/short`)
			.then(response => {
				ApiBase.setToStore(null, 'setCustomShortPages', response.data)
			})
	}

	static getOnePage (self, slug) {
		self.http('get', `${ApiBase.baseApiUrl()}/custom-pages/${slug}`)
			.then(response => {
				ApiBase.setToStore(null, 'setCustomOnePage', response.data)
			})
	}
}

export default ApiCustomPage
