import ApiCustomPage from '@/api/custom-page'

export default {
	name: 'CustomPage',
	created () {
		ApiCustomPage.getOnePage(this, this.$route.params.slug)
	},
	watch: {
    '$route.params': function () {
			ApiCustomPage.getOnePage(this, this.$route.params.slug)
    }
  }
}
